import { defineStore } from 'pinia';

interface MobileMenuState {
  isMobileMenuOpen: boolean;
}

export const useIsMobileMenuOpen = defineStore('isMobileMenuOpen', {
  state: (): MobileMenuState => ({
    isMobileMenuOpen: true,
  }),

  actions: {
    changeMobileMenu(value: boolean) {
      this.isMobileMenuOpen = value;
    },
  },
}
);
